// app/javascript/controllers/deployments_refresh_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["temporaryDeployments"]

  connect() {
    if (this.hasTemporaryDeploymentsTarget) {
      this.startRefreshTimer()
    }
  }

  disconnect() {
    this.stopRefreshTimer()
  }

  startRefreshTimer() {
    this.refreshTimeout = setTimeout(() => {
      window.location.reload()
    }, 15000)
  }

  stopRefreshTimer() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }
}
