import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static values = { branchesApiUrl: String }
  static targets = [
    "valueField", 
    "repo", 
    "branch", 
    "submitButton", 
    "envVarsContainer",
    "repoValidation",
    "branchValidation", 
    "envVarsValidation"
  ]

  connect() {
    if (this.hasRepoTarget) {
      this.validateForm()
      this.setupEnvVarListeners()
    }
  }

  setupEnvVarListeners() {
    // Listen for changes in the environment variables container
    this.envVarsContainerTarget.addEventListener('input', (event) => {
      if (event.target.matches('input[name*="[key]"], input[name*="[value]"], textarea')) {
        this.validateForm()
      }
    })
  }

  repoTargetConnected(target) {
    this.repoChoices = new Choices(target, {
      searchEnabled: true,
      itemSelectText: '',
      shouldSort: false,
      classNames: {
        containerInner: ['border', 'border-gray-300', 'block', 'w-full', 'rounded-md', 'border-0', 'py-0.5', 'pl-3', 'pr-10', 'text-gray-900', 'sm:text-sm', 'sm:leading-6']
      }
    })
    target.addEventListener('change', () => this.validateForm())
  }

  branchTargetConnected(target) {
    this.branchChoices = new Choices(target, {
      searchEnabled: true,
      itemSelectText: '',
      shouldSort: false,
      classNames: {
        containerInner: ['border', 'border-gray-300', 'block', 'w-full', 'rounded-md', 'border-0', 'py-0.5', 'pl-3', 'pr-10', 'text-gray-900', 'sm:text-sm', 'sm:leading-6']
      }
    });
    target.addEventListener('change', () => this.validateForm())
  }

  validateForm() {
    const repo = this.repoTarget.value
    const branch = this.branchTarget.value
    
    // Find the bulk textarea and check if bulk view is active
    const bulkTextarea = this.envVarsContainerTarget.querySelector('[data-nested-environment-variables-target="bulk"] textarea')
    const isBulkView = bulkTextarea && !bulkTextarea.closest('[data-nested-environment-variables-target="bulk"]').classList.contains('hidden')
    
    let hasValidEnvVars = false
    
    if (isBulkView) {
      // Validate bulk textarea
      const bulkValue = bulkTextarea.value.trim()
      hasValidEnvVars = bulkValue.split('\n')
        .some(line => {
          const trimmedLine = line.trim()
          if (!trimmedLine || !trimmedLine.includes('=')) return false
          const [key, value] = trimmedLine.split('=')
          return key.trim() && value.trim() // Ensure both key and value are non-empty
        })
    } else {
      // Validate detailed view fields
      hasValidEnvVars = Array.from(this.envVarsContainerTarget.querySelectorAll('.nested-fields')).filter(field => {
        if (field.style.display === 'none') return false
        const destroyInput = field.querySelector('input[name*="_destroy"]')
        if (destroyInput && destroyInput.value === "1") return false
        const keyInput = field.querySelector('input[name*="[key]"]')
        const valueInput = field.querySelector('input[name*="[value]"]')
        // More strict validation to ensure both key and value are present and non-empty
        const keyValue = keyInput?.value?.trim() || ''
        const valueValue = valueInput?.value?.trim() || ''
        return keyValue.length > 0 && valueValue.length > 0
      }).length > 0
    }

    // Update validation messages visibility
    this.repoValidationTarget.classList.toggle('hidden', !!repo)
    this.branchValidationTarget.classList.toggle('hidden', !!branch)
    this.envVarsValidationTarget.classList.toggle('hidden', hasValidEnvVars)

    const isValid = repo && branch && hasValidEnvVars
    
    this.submitButtonTarget.disabled = !isValid
    this.submitButtonTarget.classList.toggle('opacity-50', !isValid)
    this.submitButtonTarget.classList.toggle('cursor-not-allowed', !isValid)

    // Show/hide the popover based on validation
    const popover = document.getElementById('deployValidationPopover')
    popover.classList.toggle('hidden', isValid)
  }

  async updateRepo(event) {
    this.branchChoices.removeActiveItems();

    await this.branchChoices.setChoices(async () => {
      const repoName = event.target.options[event.target.selectedIndex].text;
      if (!repoName) { return [] }

      const query = new URLSearchParams({repo: repoName});
      const apiUrl = `${this.branchesApiUrlValue}?${query}`;

      const response = await fetch(apiUrl);
      const branches = await response.json();

      const formattedBranches = branches
        .sort((a, b) => {
          if (a === "main" || a === "master") return -1;
          if (b === "main" || b === "master") return 1;
          return a.localeCompare(b);
        })
        .map(branch => ({ value: branch, label: branch }));

      return formattedBranches;
    }, "value", "label", true);

    const firstChoice = this.branchChoices.choiceList.element.firstChild.innerText;
    this.branchChoices.setChoiceByValue(firstChoice);
    
    this.validateForm()
  }
}
