import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'
import { parseISO, parse } from 'date-fns';

export default class extends Controller {
  static targets = ["apex-chart", "spinner"]
  static values = { deploymentId: Number }

  connect() {
    this.chart = null
    const initialParams = this.getInitialParams()
    this.fetchData(initialParams)
    this.element.addEventListener("filter-changed", this.handleFilterChange.bind(this))
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
  }

  async fetchDataTokens(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/data?source=tokens&${new URLSearchParams(params)}`)
    if (!response.ok) {
      const text = await response.text()
      console.error('Error fetching tokens data:', text)
      throw new Error('Failed to fetch tokens data')
    }
    return await response.json()
  }

  async fetchDataExecutionTime(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/data?source=execution_time&${new URLSearchParams(params)}`)
    if (!response.ok) {
      const text = await response.text()
      console.error('Error fetching execution time data:', text)
      throw new Error('Failed to fetch execution time data')
    }
    return await response.json()
  }

  async fetchDataCrewVersions() {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_data/crew_versions`)
    if (!response.ok) {
      const text = await response.text()
      console.error('Error fetching crew versions data:', text)
      throw new Error('Failed to fetch crew versions data')
    }
    return await response.json()
  }

  async fetchDataTest(params) {
    const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/quality_sampling/test_data?${new URLSearchParams(params)}`)
    if (!response.ok) {
      const text = await response.text()
      console.error('Error fetching test data:', text)
      throw new Error('Failed to fetch test data')
    }
    return await response.json()
  }


  async fetchData(params = {}) {
    this.showSpinner()
    try{
      const [dataTokens, dataExecutionTime, dataCrewVersions, dataTest] = await Promise.all([
        this.fetchDataTokens(params),
        this.fetchDataExecutionTime(params),
        this.fetchDataCrewVersions(),
        this.fetchDataTest(params),
      ])
      this.renderChart(dataTokens, dataExecutionTime, dataCrewVersions, dataTest, params.aggregation)
    } catch (error) {
      console.error('Error fetching data:', error.message)
    } finally {
      this.hideSpinner()
    }
  }

  renderChart(dataTokens, dataExecutionTime, dataCrewVersions, dataTest, aggregation) {
    if (this.chart) {
      this.chart.destroy()
    }
    this.chart = document.querySelector('#apex-line-chart')

    const options = this.getChartOptions(dataTokens, dataExecutionTime, dataCrewVersions, dataTest, aggregation)
    if (this.chart && typeof ApexCharts !== 'undefined') {
      this.chart = new ApexCharts(this.chart, options)
      this.chart.render()
    }
  }

  getChartOptions(dataTokens, dataExecutionTime, dataCrewVersions, dataTest, aggregation) {
    const parsedLabels = this.parseLabels(dataTokens.labels)
    const tokenTitle = aggregation === "total" ? 'Total Prompt Tokens' : 'Average Prompt Tokens';
    const executionTimeTitle = aggregation === "total" ? 'Total Execution Time' : 'Average Execution Time';
    const crewVersionsDataPoints = parsedLabels.map((label, index) => {
      const originalLabel = dataTokens.labels[index];
      const versionIndex = dataCrewVersions.labels.indexOf(originalLabel);

      return {
        x: label.toISOString(),
        y: versionIndex !== -1 ? dataTokens.data[index] : null,
        version: versionIndex !== -1 ? dataCrewVersions.crew_versions[versionIndex] : null
      };
    });

    const stringLabels = parsedLabels.map(label => label.toISOString())

    // Average scores processing
    const scoresMap = new Map(dataTest.scores.map(item => [item.date, item.scores]));
    const filledScores = parsedLabels.map(date => ({
      date: date.toISOString().split('T')[0],
      scores: scoresMap.get(date.toISOString().split('T')[0]) || []
    }));

    // Hallucination scores processing
    const hallucinationScoresMap = new Map(dataTest.hallucination_scores.map(item => [item.date, item.scores]));
    const filledHallucinationScores = parsedLabels.map(date => ({
      date: date.toISOString().split('T')[0],
      scores: hallucinationScoresMap.get(date.toISOString().split('T')[0]) || []
    }));

    const averageScoresData = filledScores.map(item => ({
      x: item.date,
      y: item.scores.length ? this.calculateAverage(item.scores.map(Number)) : 0
    }));

    const averageHallucinationScoresData = filledHallucinationScores.map(item => ({
      x: item.date,
      y: item.scores.length ? Math.min(10, Math.max(0, 10 - this.calculateAverage(item.scores.map(Number)))) : 0
    }));

    const scoreColor = '#4ECDC4'
    const hallucinationScoreColor = '#9747FF'

    // Show only when score > 0
    const scoreMarkers = averageScoresData.map((item, index) => ({
      seriesIndex: 2,
      dataPointIndex: index,
      size: (item.y > 0) ? 3 : 0,
      fillColor: scoreColor,
      strokeColor: scoreColor,
    }));

    const hallucinationScoreMarkers = averageHallucinationScoresData.map((item, index) => ({
      seriesIndex: 3,
      dataPointIndex: index,
      size: (item.y > 0) ? 3 : 0,
      fillColor: hallucinationScoreColor,
      strokeColor: hallucinationScoreColor,
    }));

    return {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      series: [
        {
          name: tokenTitle,
          type: 'area',
          data: dataTokens.data,
          color: '#EB6658'
        },
        {
          name: executionTimeTitle,
          type: 'area',
          data: dataExecutionTime.data,
          color: '#262626'
        },
        {
          name: 'Score',
          type: 'area',
          data: averageScoresData.map(item => item.y),
          color: scoreColor
        },
        {
          name: 'Hallucination Score',
          type: 'area',
          data: averageHallucinationScoresData.map(item => item.y),
          color: hallucinationScoreColor
        },
        {
          name: 'Crew Versions',
          type: 'scatter',
          data: crewVersionsDataPoints.map(point => {
            if (point.version) return [0,0]
          }),
        }
      ],
      xaxis: {
        type: 'datetime',
        categories: stringLabels,
        labels: {
          format: 'dd MMM yyyy',
        },
        title: {
          text: 'Date'
        }
      },
      colors: ['#EB6658', '#262626', scoreColor, hallucinationScoreColor, '#3147FF'],
      fill: {
        type: ['gradient', 'gradient', 'gradient', 'gradient', 'solid'],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.75,
          opacityTo: 0.55,
          stops: [0, 95, 100]
        }
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2, 2, 0]
      },
      markers: {
        size: [3, 3, 3, 3, 8],
        shape: ['circle', 'circle', 'circle', 'circle', 'triangle'],
        colors: ['#EB6658', '#262626', scoreColor, hallucinationScoreColor, '#3147FF'],
        discrete: [...scoreMarkers, ...hallucinationScoreMarkers]
      },
      dataLabels: {
        enabled: false
      },
      yaxis: [
        {
          seriesName: tokenTitle,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#EB6658'
          },
          labels: {
            style: {
              colors: '#EB6658',
            },
            formatter: function (value) {
              if (value != null) {
                return value.toFixed(2);
              }
              return "";
            }
          },
          title: {
            text: tokenTitle,
            style: {
              color: '#EB6658',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        {
          seriesName: executionTimeTitle,
          opposite: true,
          labels: {
            style: {
              colors: '#262626',
            },
            formatter: function (value) {
              if (value != null) {
                return value.toFixed(2);
              }
              return "";
            }
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#262626'
          },
          title: {
            text: executionTimeTitle,
            style: {
              color: '#262626',
            }
          },
        },
        {
          seriesName: 'Score',
          min: 0.0,
          max: 10.0,
          tickAmount: 10,
          decimalsInFloat: 1,
          labels: {
            style: {
              colors: scoreColor,
            },
            formatter: function (value) {
              if (value != null) {
                return value.toFixed(1);
              }
              return "null";
            }
          },
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: scoreColor
          },
          title: {
            text: "Overall Score",
            style: {
              color: scoreColor,
            }
          },
        },
        {
          seriesName: 'HallucinationScore',
          min: 0.0,
          max: 10.0,
          tickAmount: 10,
          decimalsInFloat: 1,
          labels: {
            style: {
              colors: hallucinationScoreColor,
            },
            formatter: function (value) {
              if (value != null) {
                return value.toFixed(1);
              }
              return "null";
            }
          },
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: hallucinationScoreColor
          },
          title: {
            text: "Hallucination Score",
            style: {
              color: hallucinationScoreColor,
            }
          },
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          format: 'dd MMM yyyy'
        },
        y: {
          formatter: function (y, { seriesIndex, dataPointIndex, w }) {
            if (seriesIndex === 4) {
              return crewVersionsDataPoints[dataPointIndex].version
            }
            let tooltipText = y !== null ? y.toLocaleString() : y;
            if (seriesIndex === 2) { // Check if it's the "Score" series
              const hallucinationScore = averageHallucinationScoresData[dataPointIndex].y;
              tooltipText += ` (Hallucination Score: ${hallucinationScore.toFixed(1)})`;
            }
            return tooltipText;
          }
        }
      },
    };
  }

  parseLabels(labels) {
    const timeframe = this.timeFrameValue()
    return labels.map(label => {
      if (timeframe === 'week') {
        return parse(label, 'yyyy-MM-dd', new Date())
      } else if (timeframe === 'month') {
        return parse(label, 'yyyy-MM', new Date())
      } else {
        return parseISO(label)
      }
    })
  }

  timeFrameValue() {
    return document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="timeframe"]').value
  }

  handleFilterChange(event) {
    this.fetchData(event.detail)
  }

  getInitialParams() {
    return {
      timeframe: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="timeframe"]').value,
      period: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="period"]').value,
      distribution: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="distribution"]').value,
      start_date: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="customStartDate"]')?.value,
      end_date: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="customEndDate"]')?.value,
      aggregation: document.querySelector('[data-crew-dashboard--chart-filter-delegator-target="aggregation"]').value,
    }
  }

  calculateAverage(scores) {
    return scores.reduce((sum, score) => sum + score, 0) / scores.length
  }
}
