import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["reasonModal", "confirmationModal", "reasonForm"]

  connect() {
    this.initializeModals()
  }

  initializeModals() {
    this.reasonModalElement = this.reasonModalTarget
    this.confirmationModalElement = this.confirmationModalTarget
  }

  openReasonModal(event) {
    console.log("Opening reason modal")
    event.preventDefault()
    this.reasonModalElement.classList.remove("hidden")
  }

  closeReasonModal() {
    this.reasonModalElement.classList.add("hidden")
  }

  openConfirmationModal(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)
    const reason = formData.get("cancellation_reason")
    
    fetch("/crewai_plus/settings/billing/cancel-subscription-reason", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ cancellation_reason: reason })
    })
    
    this.reasonModalElement.classList.add("hidden")
    this.confirmationModalElement.classList.remove("hidden")
  }

  closeConfirmationModal() {
    this.confirmationModalElement.classList.add("hidden")
  }
}
