import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from '@xyflow/react';
import UICrewTaskNode from './UICrewTaskNode';
import UICrewConnectionLine from './UICrewConnectionLine';
import { UICrewAnimatedEdge } from './UICrewAnimatedEdge';
import UICrewTaskModal from './UICrewTaskModal';

const nodeTypes = {
  taskNode: UICrewTaskNode,
};

const edgeTypes = {
  animated: UICrewAnimatedEdge,
};

function UiCrewNodes({ crew }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [availableTools, setAvailableTools] = useState([]);

  // Update loadSavedPositions to use the new endpoint
  const loadSavedPositions = useCallback(() => {
    return fetch(`/crewai_plus/ui_studio/ui_crews/${crew.id}/task_positions`)
      .then(response => response.json())
      .then(data => data.positions || {})
      .catch(error => {
        console.error('Error loading positions:', error);
        return {};
      });
  }, [crew.id]);

  // Save positions
  const savePositions = useCallback((updatedNodes) => {
    const positions = {};
    updatedNodes.forEach(node => {
      positions[node.id] = { x: node.position.x, y: node.position.y };
    });

    fetch(`/crewai_plus/ui_studio/ui_crews/${crew.id}/update_positions`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ positions })
    })
    .then(response => response.json())
    .then(data => console.log('Positions updated:', data))
    .catch(error => console.error('Error updating positions:', error));
  }, [crew.id]);

  // Handle node movement
  const onNodeDragStop = useCallback((event, node) => {
    savePositions(nodes);
  }, [nodes]);

  const handleNodeClick = useCallback((taskData) => {
    setSelectedTask(taskData);
  }, []);

  const handleAgentChange = useCallback((taskId, newAgent) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === taskId
          ? {
              ...node,
              data: {
                ...node.data,
                agent: crew.agents.find(agent => agent.role === newAgent).role,
                tools: crew.agents.find(agent => agent.role === newAgent).tools || []
              }
            }
          : node
      )
    );
    setSelectedTask((prevTask) =>
      prevTask && prevTask.id === taskId
        ? {
            ...prevTask,
            agent: crew.agents.find(agent => agent.role === newAgent).role,
            tools: crew.agents.find(agent => agent.role === newAgent).tools || []
          }
        : prevTask
    );
  }, [crew.agents]);

  const handleToolsChange = useCallback((taskId, newTools) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === taskId
          ? { ...node, data: { ...node.data, tools: newTools } }
          : node
      )
    );
    setSelectedTask((prevTask) =>
      prevTask && prevTask.id === taskId
        ? { ...prevTask, tools: newTools }
        : prevTask
    );
  }, []);

  // Add these new handlers
  const handleDescriptionChange = useCallback((taskId, newDescription) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === taskId
          ? { ...node, data: { ...node.data, description: newDescription } }
          : node
      )
    );
    setSelectedTask((prevTask) =>
      prevTask && prevTask.id === taskId
        ? { ...prevTask, description: newDescription }
        : prevTask
    );
  }, []);

  const handleExpectedOutputChange = useCallback((taskId, newExpectedOutput) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === taskId
          ? { ...node, data: { ...node.data, expected_output: newExpectedOutput } }
          : node
      )
    );
    setSelectedTask((prevTask) =>
      prevTask && prevTask.id === taskId
        ? { ...prevTask, expected_output: newExpectedOutput }
        : prevTask
    );
  }, []);

  const updateConnections = useCallback((newEdges) => {
    const connectionMap = {};
    const sourceToTargets = {};
    const targetToSources = {};

    // Build connection maps
    newEdges.forEach(edge => {
      const sourceNode = nodes.find(node => node.id === edge.source);
      const targetNode = nodes.find(node => node.id === edge.target);

      if (sourceNode && targetNode) {
        // Map for API payload
        if (!connectionMap[targetNode.data.name]) {
          connectionMap[targetNode.data.name] = [];
        }
        connectionMap[targetNode.data.name].push(sourceNode.data.name);

        // Source to targets mapping
        if (!sourceToTargets[sourceNode.data.name]) {
          sourceToTargets[sourceNode.data.name] = [];
        }
        sourceToTargets[sourceNode.data.name].push(targetNode.data.name);

        // Target to sources mapping
        if (!targetToSources[targetNode.data.name]) {
          targetToSources[targetNode.data.name] = [];
        }
        targetToSources[targetNode.data.name].push(sourceNode.data.name);
      }
    });

    // Log the current connection state for debugging
    console.log('Connection Maps:', {
      connectionMap,
      sourceToTargets,
      targetToSources
    });

    fetch(`/crewai_plus/ui_studio/ui_crews/${crew.id}/update_connections`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ connections: connectionMap })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Connections updated:', data);
      // Update the nodes with the new context information and async_execution flag
      setNodes(prevNodes => prevNodes.map(node => {
        const updatedTask = data.tasks.find(task => task.name === node.data.name);
        if (updatedTask) {
          let isAsync = updatedTask.async_execution;

          // Rule 1: Final tasks (no outgoing connections) should never be async
          if (!sourceToTargets[node.data.name] || sourceToTargets[node.data.name].length === 0) {
            isAsync = false;
          }

          // Rule 2: If a task is part of a chain where one task sends context to multiple tasks
          // and those tasks have connections between them, they should not be async
          if (isAsync) {
            const nodeSources = targetToSources[node.data.name] || [];
            nodeSources.forEach(source => {
              const sourceTargets = sourceToTargets[source] || [];
              if (sourceTargets.length > 1) {
                // Check if this node and any of its "siblings" (other targets from the same source)
                // have connections between them (in either direction)
                const siblings = sourceTargets.filter(target => target !== node.data.name);
                siblings.forEach(sibling => {
                  const hasConnectionToSibling = sourceToTargets[node.data.name] && sourceToTargets[node.data.name].includes(sibling);
                  const hasConnectionFromSibling = sourceToTargets[sibling] && sourceToTargets[sibling].includes(node.data.name);

                  if (hasConnectionToSibling || hasConnectionFromSibling) {
                    isAsync = false;
                    // Also ensure the sibling is not async by updating its node
                    setNodes(currentNodes => currentNodes.map(n =>
                      n.data.name === sibling ?
                      { ...n, data: { ...n.data, async_execution: false } } : n
                    ));
                  }
                });
              }
            });
          }

          return {
            ...node,
            data: {
              ...node.data,
              context: updatedTask.context,
              async_execution: isAsync
            }
          };
        }
        return node;
      }));
    })
    .catch(error => console.error('Error updating connections:', error));
  }, [crew.id, nodes]);

  useEffect(() => {
    if (crew) {
      loadSavedPositions().then(savedPositions => {
        // Aggregate all tools from tasks and agents
        const allTools = new Set();
        crew.agents.forEach(agent => {
          if (agent.tools && Array.isArray(agent.tools)) {
            agent.tools.forEach(tool => allTools.add(tool));
          }
        });
        crew.tasks.forEach(task => {
          if (task.tools && Array.isArray(task.tools)) {
            task.tools.forEach(tool => allTools.add(tool));
          }
        });
        setAvailableTools(Array.from(allTools));

        const newNodes = [];
        const newEdges = [];
        const sourceToTargets = {};
        const targetToSources = {};

        let previousTaskName = null;

        // First pass: Create all nodes and build the connection maps
        crew.tasks.forEach((task, index) => {
          const savedPosition = savedPositions[task.name] || { x: (index + 1) * 300, y: 0 };

          // Build connection maps based on task context
          if (task.context && task.context.length > 0) {
            task.context.forEach(sourceName => {
              // Source to targets mapping
              if (!sourceToTargets[sourceName]) {
                sourceToTargets[sourceName] = [];
              }
              sourceToTargets[sourceName].push(task.name);

              // Target to sources mapping
              if (!targetToSources[task.name]) {
                targetToSources[task.name] = [];
              }
              targetToSources[task.name].push(sourceName);
            });
          }

          let isAsync = task.async_execution || false;

          // Apply Rule 1: Final tasks should never be async
          const hasOutgoingConnections = crew.tasks.some(t =>
            t.context && t.context.includes(task.name)
          );
          if (!hasOutgoingConnections) {
            isAsync = false;
          }

          newNodes.push({
            id: task.name,
            type: 'taskNode',
            position: savedPosition,
            data: {
              id: task.name,
              name: task.name,
              description: task.description,
              agent: task.agent_role,
              expected_output: task.expected_output,
              agents: crew.agents,
              tools: task.tools || availableTools || [],
              onClick: handleNodeClick,
              context: task.context || [],
              async_execution: isAsync,
            },
          });

          // Create edges based on context
          if (task.context && task.context.length > 0) {
            task.context.forEach(sourceName => {
              newEdges.push({
                id: `${sourceName}-${task.name}`,
                source: sourceName,
                target: task.name,
                type: 'animated',
              });
            });
          } else if (previousTaskName && task.context !== null) {
            newEdges.push({
              id: `${previousTaskName}-${task.name}`,
              source: previousTaskName,
              target: task.name,
              type: 'animated',
            });
          }

          previousTaskName = task.context !== null ? task.name : null;
        });

        // Second pass: Apply Rule 2 for sibling connections
        const finalNodes = newNodes.map(node => {
          if (node.data.async_execution) {
            const nodeSources = targetToSources[node.data.name] || [];
            let shouldBeAsync = true;

            nodeSources.forEach(source => {
              const sourceTargets = sourceToTargets[source] || [];
              if (sourceTargets.length > 1) {
                const siblings = sourceTargets.filter(target => target !== node.data.name);
                siblings.forEach(sibling => {
                  // Check for connections between siblings
                  const hasConnectionToSibling = sourceToTargets[node.data.name] && sourceToTargets[node.data.name].includes(sibling);
                  const hasConnectionFromSibling = crew.tasks.find(t =>
                    t.name === sibling && t.context && t.context.includes(node.data.name)
                  );

                  if (hasConnectionToSibling || hasConnectionFromSibling) {
                    shouldBeAsync = false;
                  }
                });
              }
            });

            return {
              ...node,
              data: {
                ...node.data,
                async_execution: shouldBeAsync
              }
            };
          }
          return node;
        });

        setNodes(finalNodes);
        setEdges(newEdges);
      });
    }
  }, [crew, handleNodeClick, loadSavedPositions]);

  const onConnect = useCallback(
    (params) => {
      if (params.source !== params.target) {
        setEdges((eds) => {
          const newEdges = addEdge({ ...params, type: 'animated' }, eds);
          updateConnections(newEdges);
          return newEdges;
        });
      }
    },
    [setEdges, updateConnections]
  );

  const onEdgesDelete = useCallback(
    (edgesToDelete) => {
      setEdges((eds) => {
        const newEdges = eds.filter((edge) => !edgesToDelete.some((e) => e.id === edge.id));
        updateConnections(newEdges);
        return newEdges;
      });
    },
    [setEdges, updateConnections]
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgesDelete={onEdgesDelete}
        onNodeDragStop={onNodeDragStop}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={UICrewConnectionLine}
        connectionLineType="smoothstep"
        fitView
        nodesDraggable={true}
        nodesConnectable={true}
        elementsSelectable={true}
        edgesFocusable={true}
        selectNodesOnDrag={false}
        connectionMode="loose"
      >
        <Controls />
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
      {selectedTask && (
        <UICrewTaskModal
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          onAgentChange={handleAgentChange}
          onToolsChange={handleToolsChange}
          onDescriptionChange={handleDescriptionChange}
          onExpectedOutputChange={handleExpectedOutputChange}
          availableAgents={crew.agents}
          availableTools={availableTools}
          crewId={crew.id}
        />
      )}
    </div>
  );
}

export default UiCrewNodes;
