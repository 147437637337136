import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "fields", "bulk", "detailedLabel", "bulkLabel", "addButton"]
  static values = {
    view: String
  }

  counter = 0

  connect() {
    this.viewValue = this.viewValue || "detailed"

    const form = this.element.closest('form')
    if (form) {
      form.addEventListener('submit', this.handleSubmit.bind(this))
    }
  }

  toggleView(event) {
    event.preventDefault()

    if (this.viewValue === "detailed") {
      this.updateBulkFromFields()
      this.viewValue = "bulk"
      this.fieldsTarget.classList.add("hidden")
      this.bulkTarget.classList.remove("hidden")
      this.detailedLabelTarget.classList.add("hidden")
      this.bulkLabelTarget.classList.remove("hidden")
      this.addButtonTarget.setAttribute("disabled", "true")
    } else {
      this.updateFieldsFromBulk()
      this.viewValue = "detailed"
      this.fieldsTarget.classList.remove("hidden")
      this.bulkTarget.classList.add("hidden")
      this.detailedLabelTarget.classList.remove("hidden")
      this.bulkLabelTarget.classList.add("hidden")
      this.addButtonTarget.removeAttribute("disabled")
    }
  }

  updateBulkFromFields() {
    const uniquePairs = new Map()

    this.fieldsTarget.querySelectorAll('.nested-fields').forEach(field => {
      if (field.style.display === 'none') return

      const destroyInput = field.querySelector('input[name*="_destroy"]')
      if (destroyInput && destroyInput.value === "1") return

      const keyInput = field.querySelector("input[name*='[key]']")
      const valueInput = field.querySelector("input[name*='[value]']")
      const key = keyInput?.value?.trim()

      if (key && valueInput && !uniquePairs.has(key)) {
        uniquePairs.set(key, valueInput.value)
      }
    })

    const pairs = Array.from(uniquePairs.entries()).map(([key, value]) => `${key}=${value}`)
    this.bulkTarget.querySelector('textarea').value = pairs.join('\n')
  }

  updateFieldsFromBulk() {
    const textarea = this.bulkTarget.querySelector('textarea')
    const lines = textarea.value.trim().split('\n').filter(line => line.trim())
    const newValues = new Map()

    lines.forEach(line => {
      const [key, ...valueParts] = line.split('=')
      const trimmedKey = key?.trim()
      if (trimmedKey) {
        newValues.set(trimmedKey, valueParts.join('='))
      }
    })

    this.fieldsTarget.querySelectorAll('.nested-fields').forEach(field => {
      const destroyInput = field.querySelector('input[name*="_destroy"]')
      if (destroyInput) {
        destroyInput.value = "1"
        field.style.display = "none"
      } else {
        field.remove()
      }
    })

    newValues.forEach((value, key) => {
      const existingField = Array.from(this.fieldsTarget.querySelectorAll('.nested-fields'))
        .find(field => {
          if (field.style.display === 'none') return false
          const keyInput = field.querySelector("input[name*='[key]']")
          return keyInput?.value?.trim() === key
        })

      if (existingField) {
        const valueInput = existingField.querySelector("input[name*='[value]']")
        const destroyInput = existingField.querySelector('input[name*="_destroy"]')

        valueInput.value = value
        if (destroyInput) {
          destroyInput.value = "0"
          existingField.style.display = ""
        }
      } else {
        const field = this.createField()
        const keyInput = field.querySelector("input[name*='[key]']")
        const valueInput = field.querySelector("input[name*='[value]']")

        keyInput.value = key
        valueInput.value = value

        this.fieldsTarget.appendChild(field)
      }
    })
  }

  add(event) {
    event.preventDefault()

    const emptyField = Array.from(this.fieldsTarget.querySelectorAll('.nested-fields'))
      .find(field => {
        if (field.style.display === 'none') return false
        const keyInput = field.querySelector("input[name*='[key]']")
        return !keyInput?.value?.trim()
      })

    if (emptyField) {
      const keyInput = emptyField.querySelector("input[name*='[key]']")
      if (keyInput) keyInput.focus()
      return
    }

    const field = this.createField()
    this.fieldsTarget.appendChild(field)

    const keyInput = field.querySelector("input[name*='[key]']")
    if (keyInput) keyInput.focus()
  }

  remove(event) {
    event.preventDefault()
    const field = event.target.closest(".nested-fields")
    if (!field) return

    const destroyInput = field.querySelector('input[name*="_destroy"]')
    if (destroyInput) {
      destroyInput.value = "1"
      field.style.display = "none"
    } else {
      field.remove()
    }

    if (this.viewValue === "bulk") {
      this.updateBulkFromFields()
    }

    const remainingField = Array.from(this.fieldsTarget.querySelectorAll('.nested-fields'))
      .find(f => f.style.display !== 'none')
    
    if (remainingField) {
      const input = remainingField.querySelector('input[name*="[key]"]')
      if (input) {
        input.dispatchEvent(new Event('input', { bubbles: true }))
      }
    } else {
      this.element.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  handleSubmit(event) {
    if (this.viewValue === "bulk") {
      this.updateFieldsFromBulk()
    }
  }

  createField() {
    const timestamp = Date.now()
    const uniqueId = `${timestamp}${this.counter++}`
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, uniqueId)
    const field = document.createElement('div')
    field.classList.add('nested-fields')
    field.innerHTML = content
    return field
  }

  copyFromOrg(event) {
    event.preventDefault()
    const button = event.currentTarget
    const key = button.dataset.key
    const value = button.dataset.value
    
    // Create new field directly
    const field = this.createField()
    this.fieldsTarget.appendChild(field)
    
    // Set the key and value
    const keyInput = field.querySelector('input[name*="[key]"]')
    const valueInput = field.querySelector('input[name*="[value]"]')
    
    if (keyInput && valueInput) {
      keyInput.value = key
      valueInput.value = value
      
      // Dispatch input event to trigger validation
      keyInput.dispatchEvent(new Event('input', { bubbles: true }))
      valueInput.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }
}
