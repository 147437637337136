import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    interval: Number,
    type: String
  };
  static targets = ["loadingIndicator"];

  connect() {
    document.addEventListener('logs-tab-visible', this.handleTabVisible.bind(this));
    document.addEventListener('logs-tab-hidden', this.handleTabHidden.bind(this));
    this.element.addEventListener('stopRefresh', this.handleStopRefresh.bind(this));
    this.element.addEventListener('deploymentInProgress', this.handleDeploymentInProgress.bind(this));
    this.element.addEventListener('deploymentOnline', this.handleDeploymentOnline.bind(this));
    this.element.addEventListener('autoScrollChanged', this.handleAutoScrollChanged.bind(this));

    this.autoScroll = localStorage.getItem('logsAutoScroll') !== 'false';
    this.isRefreshing = false;

    const anchor = window.location.hash.slice(1)
    if (anchor && anchor.includes("logs")) {
      this.tabIsVisible = true;
      this.loadPartial();

    } else {
      this.tabIsVisible = false;
    }
  }

  disconnect() {
    this.stopRefreshing();
    document.removeEventListener('logs-tab-visible', this.handleTabVisible.bind(this));
    document.removeEventListener('logs-tab-hidden', this.handleTabHidden.bind(this));
    this.element.removeEventListener('stopRefresh', this.handleStopRefresh.bind(this));
    this.element.removeEventListener('deploymentInProgress', this.handleDeploymentInProgress.bind(this));
    this.element.removeEventListener('deploymentOnline', this.handleDeploymentOnline.bind(this));
    this.element.removeEventListener('autoScrollChanged', this.handleAutoScrollChanged.bind(this));
  }

  handleDeploymentInProgress() {
    if (this.tabIsVisible && this.typeValue !== 'worker') {
      this.showLoadingIndicator();
      this.startRefreshing();
    }
  }

  handleDeploymentOnline() {
    if (this.typeValue !== 'worker') {
      this.stopRefreshing();
    } else if (this.tabIsVisible) {
      this.startRefreshing();
    }
  }

  startRefreshing() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.loadPartial();
      this.scheduleNextRefresh();
    }
  }

  stopRefreshing() {
    this.isRefreshing = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.hideLoadingIndicator();
  }

  scheduleNextRefresh() {
    if (this.isRefreshing) {
      this.timeout = setTimeout(() => {
        this.loadPartial();
        this.scheduleNextRefresh();
      }, this.intervalValue || 5000);
    }
  }

  handleAutoScrollChanged(event) {
    this.autoScroll = event.detail.enabled;
  }

  handleTabVisible(event) {
    this.loadPartial();
    this.tabIsVisible = true;
  }

  handleTabHidden(event) {
    this.tabIsVisible = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleStopRefresh() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.hideLoadingIndicator();
  }

  showLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.remove('hidden');
    }
  }

  hideLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.add('hidden');
    }
  }

  loadPartial() {
    const logsContainer = this.element.querySelector("#logs-container");
    if (!logsContainer) return;
    const scrollTop = logsContainer?.scrollTop ?? 0;
    const isAtTop = scrollTop === 0;
    const isAtBottom =
      Math.abs(
        logsContainer.scrollHeight - logsContainer.clientHeight - scrollTop
      ) < 1;

    fetch(this.urlValue, {
      headers: { Accept: "text/html" },
    })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;

        if (this.tabIsVisible) {
          this.showLoadingIndicator();
        }

        const newLogsContainer = this.element.querySelector("#logs-container");
        if (newLogsContainer) {
          if (this.autoScroll && (isAtTop || isAtBottom)) {
            newLogsContainer.scrollTop = newLogsContainer.scrollHeight;
          } else {
            newLogsContainer.scrollTop = scrollTop;
          }
        }
      })
      .catch((error) => console.error("Error loading partial:", error));
  }
}
