import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["logs", "buttonsContainer", "logsContainer", "autoScrollToggle"]

  connect() {
    this.autoScroll = localStorage.getItem('logsAutoScroll') !== 'false';
    if (this.hasAutoScrollToggleTarget) {
      this.autoScrollToggleTarget.checked = this.autoScroll;
    }
  }

  toggleAutoScroll(event) {
    this.autoScroll = event.target.checked;
    localStorage.setItem('logsAutoScroll', this.autoScroll);

    this.logsTargets.forEach(logContainer => {
      logContainer.dispatchEvent(
        new CustomEvent('autoScrollChanged', {
          detail: { enabled: this.autoScroll }
        })
      );
    });
  }

  switch(event) {
    event.preventDefault();

    const buttons =
      this.buttonsContainerTargets[0].getElementsByTagName("button");
    Array.from(buttons).forEach((button) => {
      button.classList.remove("!bg-gray-200");
      button.classList.add("bg-white");
    });
    event.target.classList.remove("bg-white");
    event.target.classList.add("!bg-gray-200");

    const type = event.target.dataset.type;
    const targetElement = this.logsTargets.find(
      (element) => element.dataset.type === type
    );

    const logs = this.logsContainerTargets[0].children;
    Array.from(logs).forEach((log) => {
      if (log === targetElement) {
        log.classList.remove("hidden");
      } else {
        log.classList.add("hidden");
      }
    });
  }
}
