import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "content"]


  show() {
    const content = this.contentTarget
    const icon = this.iconTarget
    const score = content.dataset.score
    const verdict = content.dataset.verdict
    const reasons = JSON.parse(content.dataset.reasons)

    content.innerHTML = `
      <div class="flex items-center justify-between mb-1">
        <span class="text-sm font-semibold">Hallucination Score:</span>
        <span class="text-black font-bold">${Math.min(Math.max(10 - score, 0), 10)}</span>
      </div>
      <div class="text-xs text-gray-600">
        <div class="mb-1"><strong>Verdict:</strong> ${verdict.charAt(0).toUpperCase() + verdict.slice(1)}</div>
        <div><strong>Reasons:</strong></div>
        <ul class="list-disc pl-4 -mt-5 whitespace-pre-wrap">
          ${reasons.map(reason => `<li class="mb-1">${reason}</li>`).join('')}
        </ul>
      </div>
    `

    // Position the tooltip relative to the icon
    const iconRect = icon.getBoundingClientRect()
    const contentRect = content.getBoundingClientRect()
    
    content.style.position = 'absolute'
    content.style.top = `${iconRect.bottom + 5}px`  // 5px gap below icon
    content.style.left = `${iconRect.left - (contentRect.width/2) + (iconRect.width/2)}px`  // Center align with icon

    content.classList.remove('hidden')
  }

  mouseEnterContent() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout)
    }
  }

  mouseLeaveContent() {
    this.hide()
  }

  hide() {
    this.hideTimeout = setTimeout(() => {
      this.contentTarget.classList.add('hidden')
    }, 100)
  }
}
