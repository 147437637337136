export function showFlashMessage(message, type = 'notice') {
  const flashContainerId = `flash-${type}`;

  const existingFlashContainer = document.getElementById(flashContainerId);
  if (existingFlashContainer) {
    existingFlashContainer.remove();
    if (existingFlashContainer.timeoutId) {
      clearTimeout(existingFlashContainer.timeoutId);
    }
  }

  const flashContainer = document.createElement('div')
  flashContainer.id = flashContainerId
  const baseClasses = 'flash-message fixed bottom-4 right-4 p-4 rounded shadow-lg z-50 text-white'
  const typeClasses = type === 'notice' ? 'bg-green-500' : 'bg-primary-color'
  flashContainer.className = `${baseClasses} ${typeClasses}`
  flashContainer.textContent = message

  document.body.appendChild(flashContainer)

  flashContainer.timeoutId = setTimeout(() => {
    flashContainer.classList.add('opacity-0', 'transition-opacity', 'duration-300')
    setTimeout(() => {
      document.body.removeChild(flashContainer)
    }, 300)
  }, 5000)
} 