import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "inputInUsd", "spinner"]
  static values = { deploymentId: Number }

  save() {
    const taskId = this.inputTarget.dataset.taskId
    const manualCost = this.inputTarget.value
    const manualCostInUsd = this.inputInUsdTarget.value

    this.showSpinner()

    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_tasks/${taskId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ manual_cost: manualCost, manual_cost_in_usd: manualCostInUsd })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.hideSpinner()
      } else {
        this.hideSpinner()
        this.inputTarget.value = this.inputTarget.dataset.originalValue
        this.inputInUsdTarget.value = this.inputInUsdTarget.dataset.originalValue
      }
    })
    .catch(error => {
      console.error('Error:', error)
      this.hideSpinner()
      this.inputTarget.value = this.inputTarget.dataset.originalValue
      this.inputInUsdTarget.value = this.inputInUsdTarget.dataset.originalValue
    })
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('invisible')
    this.spinnerTarget.classList.add('visible')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('invisible')
    this.spinnerTarget.classList.remove('visible')
  }
}
