import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "error", "submit"]

  connect() {
    this.validateName()
  }

  validateName() {
    const name = this.inputTarget.value
    const isValid = name.length >= 3 && name.length <= 64

    if (!isValid) {
      if (name.length < 3) {
        this.errorTarget.textContent = "Name must be at least 3 characters long"
      } else if (name.length > 64) {
        this.errorTarget.textContent = "Name must not exceed 64 characters"
      }
      this.errorTarget.classList.remove("hidden")
      this.inputTarget.classList.add("border-red-300")
      this.inputTarget.classList.remove("border-gray-300")
    } else {
      this.errorTarget.classList.add("hidden")
      this.inputTarget.classList.remove("border-red-300")
      this.inputTarget.classList.add("border-gray-300")
    }

    this.submitTarget.disabled = !isValid
  }
}
