import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list", "webHookUrlsContent", "webHookUrlsIcon"]

  connect() {
    document.addEventListener('schedule:created', this.handleScheduleCreated.bind(this))
  }

  disconnect() {
    document.removeEventListener('schedule:created', this.handleScheduleCreated.bind(this))
  }

  handleScheduleCreated(event) {
    this.refreshList()
  }

  toggleWebhookUrlsInputs() {
    this.webHookUrlsContentTarget.classList.toggle("hidden")
    this.webHookUrlsIconTarget.classList.toggle("rotate-180")
  }

  showForm(event) {
    event.preventDefault()
    fetch(event.currentTarget.getAttribute("href"), {
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
      .then(response => response.text())
      .then(html => {
        document.getElementById("new-scheduled-kickoff-form").innerHTML = html
      })
  }

  hideForm() {
    document.getElementById("new-scheduled-kickoff-form").innerHTML = ""
  }

  submitForm(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)

    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.refreshList()
          form.reset()
        } else {
          console.error("Error creating scheduled kickoff:", data.errors)
        }
      })
  }

  deleteKickoff(event) {
    event.preventDefault()
    if (!confirm("Are you sure you want to delete this scheduled kickoff?")) return

    const button = event.currentTarget
    const path = button.getAttribute("data-path")
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(path, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken
      },
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.refreshList()
        } else {
          console.error("Error deleting scheduled kickoff:", data.errors)
        }
      })
      .catch(error => {
        console.error("Error deleting scheduled kickoff:", error)
      })
  }

  refreshList() {
    fetch(window.location.href, {
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest"
      }
    })
      .then(response => response.text())
      .then(html => {
        const tempDiv = document.createElement("div")
        tempDiv.innerHTML = html
        const newList = tempDiv.querySelector("[data-scheduled-kickoffs-target='list']")
        this.listTarget.innerHTML = newList.innerHTML
      })
  }
}
