import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"];

  submitWithGet(event) {
    const form = this.formTarget;
    form.method = "get";
    form.submit();
  }
}
