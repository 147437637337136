import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = [
      "table",
      "tableInUSD",
      "totalSavings",
      "totalMinutesBeforeAutomation",
      "totalMinutesAfterAutomation",
      "totalSavingsInUsd",
      "totalCostBeforeAutomation",
      "totalCostAfterAutomation",
      "overallChart",
      "taskChart",
      "overallChartInUSD",
      "taskChartInUSD",
      "timeTab",
      "usdTab",
      "settingsTab",
      "timeContent",
      "usdContent",
      "settingsContent"
  ]
  static values = { deploymentId: Number }

  connect() {
    this.fetchRoiData()
  }

  fetchRoiData() {
    fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions_tasks_data/roi`)
      .then(response => response.json())
      .then(data => {
        this.renderRoiTable(data)
        this.fillSummary(data)
        this.renderCharts(data)
      })
  }

  renderRoiTable(data) {
    const tableHtmlInTime = `
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 m-0">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">Description</th>
            <th scope="col" class="px-6 py-3">Executions</th>
            <th scope="col" class="px-6 py-3">Total time before automation</th>
            <th scope="col" class="px-6 py-3">Total time after automation</th>
            <th scope="col" class="px-6 py-3">Total time saved</th>
          </tr>
        </thead>
        <tbody>
          ${data.results.map(task => `
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
              <td class="px-6 py-2">${task.task_description.substring(0, 100)}${task.task_description.length > 100 ? '...' : ''}</td>
              <td class="px-6 py-2">${task.total_executions}</td>
              <td class="px-6 py-2">${task.total_manual_cost_in_minutes_formatted}</td>
              <td class="px-6 py-2">${task.total_execution_time_in_minutes_formatted}</td>
              <td class="px-6 py-2">${task.roi_formatted}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    `;

    const tableHtmlInUSD = `
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 m-0">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">Description</th>
            <th scope="col" class="px-6 py-3">Executions</th>
            <th scope="col" class="px-6 py-3">Total cost before automation</th>
            <th scope="col" class="px-6 py-3">Total cost after automation</th>
            <th scope="col" class="px-6 py-3">Total savings</th>
          </tr>
        </thead>
        <tbody>
          ${data.results.map(task => `
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
              <td class="px-6 py-2">${task.task_description.substring(0, 100)}${task.task_description.length > 100 ? '...' : ''}</td>
              <td class="px-6 py-2">${task.total_executions}</td>
              <td class="px-6 py-2">${task.total_cost_before_automation_formatted}</td>
              <td class="px-6 py-2">${task.total_cost_after_automation_formatted}</td>
              <td class="px-6 py-2">${task.roi_in_usd_formatted}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    `;
    this.tableTarget.innerHTML = tableHtmlInTime;
    this.tableInUSDTarget.innerHTML = tableHtmlInUSD;
  }

  fillSummary(data) {
    // Time
    this.totalSavingsTarget.textContent = data.total_savings_formatted
    this.totalMinutesBeforeAutomationTarget.textContent = data.total_minutes_before_automation_formatted
    this.totalMinutesAfterAutomationTarget.textContent = data.total_minutes_after_automation_formatted
    // USD
    this.totalSavingsInUsdTarget.textContent = data.total_savings_in_usd_formatted
    this.totalCostBeforeAutomationTarget.textContent = data.total_cost_before_automation_formatted
    this.totalCostAfterAutomationTarget.textContent = data.total_cost_after_automation_formatted
  }

  renderCharts(data) {
    // Time
    this.renderOverallChart(data)
    this.renderTaskChart(data)
    // USD
    this.renderOverallChartInUSD(data)
    this.renderTaskChartInUSD(data)
  }

  renderOverallChart(data) {
    const options = {
      series: [{
        name: 'Minutes',
        data: [
          parseFloat(data.total_minutes_before_automation),
          parseFloat(data.total_minutes_after_automation)
        ]
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const formattedValues = [
            data.total_minutes_before_automation_formatted,
            data.total_minutes_after_automation_formatted
          ];
          return formattedValues[opts.dataPointIndex];
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
        offsetY: -20,
        position: 'top',
        distributed: true,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 5,
          opacity: 0.1
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Before Automation', 'After Automation'],
      },
      yaxis: {
        title: {
          text: 'Minutes'
        },
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            const formattedValues = [
              data.total_minutes_before_automation_formatted,
              data.total_minutes_after_automation_formatted
            ];
            return formattedValues[opts.dataPointIndex];
          }
        }
      },
      colors: ['#EB6658', '#737373']
    };

    const chart = new ApexCharts(this.overallChartTarget, options);
    chart.render();
  }

  renderTaskChart(data) {
    const taskData = data.results.map(task => ({
      x: task.task_description.substring(0, 30) + '...',
      y: [
        parseFloat(task.total_manual_cost_in_minutes),
        parseFloat(task.total_execution_time_in_minutes)
      ],
      formattedY: [
        task.total_manual_cost_in_minutes_formatted,
        task.total_execution_time_in_minutes_formatted
      ]
    }));

    const options = {
      series: [{
        name: 'Before Automation',
        data: taskData.map(task => task.y[0])
      }, {
        name: 'After Automation',
        data: taskData.map(task => task.y[1])
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return taskData[opts.dataPointIndex].formattedY[opts.seriesIndex];
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
        offsetY: -20,
        position: 'top',
        distributed: true,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 5,
          opacity: 0.1
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: taskData.map(task => task.x),
        labels: {
          rotate: -45,
          trim: true,
          maxHeight: 120
        }
      },
      yaxis: {
        title: {
          text: 'Minutes'
        },
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            return taskData[opts.dataPointIndex].formattedY[opts.seriesIndex];
          }
        }
      },
      colors: ['#EB6658', '#737373'],
      legend: {
        show: false
      }
    };

    const chart = new ApexCharts(this.taskChartTarget, options);
    chart.render();
  }

  renderOverallChartInUSD(data) {
    const options = {
      series: [{
        name: 'Costs',
        data: [
          parseFloat(data.total_cost_before_automation),
          parseFloat(data.total_cost_after_automation)
        ]
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const formattedValues = [
            data.total_cost_before_automation_formatted,
            data.total_cost_after_automation_formatted
          ];
          return formattedValues[opts.dataPointIndex];
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
        offsetY: -20,
        position: 'top',
        distributed: true,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 5,
          opacity: 0.1
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Before Automation', 'After Automation'],
      },
      yaxis: {
        title: {
          text: 'USD'
        },
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            const formattedValues = [
              data.total_cost_before_automation_formatted,
              data.total_cost_after_automation_formatted
            ];
            return formattedValues[opts.dataPointIndex];
          }
        }
      },
      colors: ['#EB6658', '#737373']
    };

    const chart = new ApexCharts(this.overallChartInUSDTarget, options);
    chart.render();
  }

  renderTaskChartInUSD(data) {
    const taskData = data.results.map(task => ({
      x: task.task_description.substring(0, 30) + '...',
      y: [
        parseFloat(task.total_cost_before_automation),
        parseFloat(task.total_cost_after_automation)
      ],
      formattedY: [
        task.total_cost_before_automation_formatted,
        task.total_cost_after_automation_formatted
      ]
    }));

    const options = {
      series: [{
        name: 'Before Automation',
        data: taskData.map(task => task.y[0])
      }, {
        name: 'After Automation',
        data: taskData.map(task => task.y[1])
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return taskData[opts.dataPointIndex].formattedY[opts.seriesIndex];
        },
        style: {
          colors: ['#fff'],
          fontSize: '12px',
          fontWeight: 'bold',
          textShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)'
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
        },
        offsetY: -20,
        position: 'top',
        distributed: true,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 5,
          opacity: 0.1
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: taskData.map(task => task.x),
        labels: {
          rotate: -45,
          trim: true,
          maxHeight: 120
        }
      },
      yaxis: {
        title: {
          text: 'USD'
        },
        labels: {
          formatter: function (val) {
            return Math.round(val)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            return taskData[opts.dataPointIndex].formattedY[opts.seriesIndex];
          }
        }
      },
      colors: ['#EB6658', '#737373'],
      legend: {
        show: false
      }
    };

    const chart = new ApexCharts(this.taskChartInUSDTarget, options);
    chart.render();
  }


  refresh() {
    this.fetchRoiData()
  }

  switchTab(event) {
    const selectedTab = event.currentTarget.dataset.tab
    const tabs = ['time', 'usd', 'settings']

    // Reset all tabs to inactive state
    tabs.forEach(tab => {
      const tabTarget = this[`${tab}TabTarget`]
      if (tabTarget) {
        tabTarget.classList.remove('text-blue-600', 'border-blue-600')
        tabTarget.classList.add('border-transparent', 'hover:text-gray-600', 'hover:border-gray-300')
      }
    })

    // Set active tab
    const activeTab = this[`${selectedTab}TabTarget`]
    if (activeTab) {
      activeTab.classList.remove('border-transparent', 'hover:text-gray-600', 'hover:border-gray-300')
      activeTab.classList.add('text-blue-600', 'border-blue-600')
    }

    // Show/hide content
    if (this.timeContentTarget) {
      this.timeContentTarget.classList.toggle('hidden', selectedTab !== 'time')
    }
    if (this.usdContentTarget) {
      this.usdContentTarget.classList.toggle('hidden', selectedTab !== 'usd')
    }
    if (this.settingsContentTarget) {
      this.settingsContentTarget.classList.toggle('hidden', selectedTab !== 'settings')
    }

    // Trigger chart resize to fix any rendering issues
    if (selectedTab === 'usd') {
      window.dispatchEvent(new Event('resize'))
    }
  }
}
